<template>
  <div class="mod-shoppingrecord">
    <el-form
      :inline="true"
      :model="searchForm"
      @keyup.enter.native="getDataList()"
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.orderId"
            placeholder="订单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.name"
            placeholder="用户名字"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="充值日期" class="typeInput">
          <el-date-picker
            v-model="value"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      stripe
      border
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="orderId"
        header-align="center"
        align="center"
        label="订单号"
      >
      </el-table-column>
      <el-table-column
        prop="userId"
        header-align="center"
        align="center"
        label="消费用户ID"
      >
      </el-table-column>
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="消费用户名字"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="用户电话"
      >
      </el-table-column>
      <el-table-column
        prop="price"
        header-align="center"
        align="center"
        label="消费金额"
      >
        <template slot-scope="scope">
          <el-tag effect="plain" type="success">￥{{ scope.row.price }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="shoppingTime"
        header-align="center"
        align="center"
        label="消费时间"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        orderId: '',
        name: '',
        startTime: '',
        endTime: '',
      },
      value: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      if (this.value?.length) {
        this.searchForm.startTime = this.value[0];
        this.searchForm.endTime = this.value[1];
      } else {
        this.searchForm.startTime = null;
        this.searchForm.endTime = null;
      }
      this.$http({
        url: '/cm/shoppingrecord/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          orderId: this.searchForm.orderId,
          name: this.searchForm.name,
          startTime: this.searchForm.startTime,
          endTime: this.searchForm.endTime,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
};
</script>

<style>
.el-popper {
  width: auto;
  max-height: 500px;
  max-width: 550px;
}
</style>

<style lang="scss" scoped>
.DatepickerTime {
  width: 400px !important;
  .el-range-separator {
    width: 20px;
    font-size: 12px;
  }
}
</style>
